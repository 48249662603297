import React from 'react'

import me from '../assets/imgs/7.png'
import me2 from '../assets/imgs/7.png'

function Seven() {
  return (
    <>

      <header class="header" id="home">
        <div class="container">
          <div class="infos">
            <h6 class="title">Hobbies Tracking Mobile App</h6>
          </div>
          <div class="img-holder">
            <img src={me} style={{ marginBottom: '120px' }} alt="" />
          </div>
        </div>
      </header>

      <section id="about" class="section mt-3">
        <div class="container mt-5">
          {/* <img src={me} style={{ height:'540px',width:'100%' }} alt="" /> */}

          <div class="row text-center text-md-left">
            <div class="col-md-3">
              <img src={me2} alt="" class="img-thumbnail mb-4" />
            </div>
            <div class="pl-md-4 col-md-9">
              <h6 class="title">Hobbies Tracking Mobile App</h6>
              {/* <p class="subtitle">Web & Mobile Developer</p> */}
              <p>
              The "Hobbies Tracking Mobile App" is a delightful application developed for personal enjoyment and leisure. This mobile app serves as a dedicated tool for tracking and managing hobbies, providing users with a fun and interactive platform to organize their recreational activities.
                <br />
                <br />


                <b>Monthly Hobby Calendar:</b>
                The app features a visually appealing calendar view displaying monthly dated numbers. Each date is color-coded from light green to very dark green, indicating the number of hobbies completed on that day. This intuitive visualization allows users to track their hobby progress at a glance.

                <br />
                <br />
                <b>Hobbies List Management:</b>
                Users can access a list of hobbies within the app, which is fully customizable. New hobbies can be easily added to the list, and existing hobbies can be edited as needed. Each hobby entry includes a checkbox, allowing users to mark hobbies as completed once they are done.

                <br />
                <br />
                <b>Interactive User Interface:</b>
                The app boasts an intuitive and user-friendly interface, making it easy for users to navigate and interact with their hobby data. The calendar view and hobbies list are seamlessly integrated, providing a cohesive user experience.

                <br />
                <br />
                The "Hobbies Tracking Mobile App" was developed with the sole intention of providing users with a fun and engaging tool for managing their leisure pursuits. While primarily designed for personal enjoyment, the app offers valuable functionality for individuals looking to stay organized and motivated in pursuing their hobbies.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Seven
