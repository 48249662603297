import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom'

// pages
import One from './pages/One';
import Two from './pages/Two';
import Three from './pages/Three';
import Four from './pages/Four';
import Five from './pages/Five';
import Six from './pages/Six';
import Seven from './pages/Seven';
import Eight from './pages/Eight';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
      <Routes>
        <Route element={<App />} path='/' />
        <Route element={<One/>} path='/project:portal' />
        <Route element={<Two/>} path='/project:paintings' />
        <Route element={<Three/>} path='/project:socialchat' />
        <Route element={<Four/>} path='/project:service' />
        <Route element={<Five/>} path='/project:rcm' />
        <Route element={<Six/>} path='/project:personalchat' />
        <Route element={<Seven/>} path='/project:hobbies' />
        <Route element={<Eight/>} path='/project:cgpaFinder' />
      </Routes>
  </Router>
);
