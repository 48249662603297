import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'
import { getAuth } from "firebase/auth";
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyD2CQBw6p-yzXApA8QgvflLC3DNN4aXoz4",
    authDomain: "portfolio-54af8.firebaseapp.com",
    projectId: "portfolio-54af8",
    storageBucket: "portfolio-54af8.appspot.com",
    messagingSenderId: "236597746492",
    appId: "1:236597746492:web:fe20f8daba014c9abb5c39",
    measurementId: "G-FTFQMQ1FR0"
};

const firebase = initializeApp(firebaseConfig);
export const auth = getAuth(firebase);
export const db = getFirestore(firebase);
export const storage = getStorage(firebase);
