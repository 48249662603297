import React from 'react'

import me from '../assets/imgs/4.png'
import me2 from '../assets/imgs/4.png'

function Three() {
  return (
    <>

      <header class="header" id="home">
        <div class="container">
          <div class="infos">
            <h6 class="title">Social Chatting Mobile App</h6>
          </div>
          <div class="img-holder">
            <img src={me} style={{ marginBottom: '120px' }} alt="" />
          </div>
        </div>
      </header>

      <section id="about" class="section mt-3">
        <div class="container mt-5">
          {/* <img src={me} style={{ height:'540px',width:'100%' }} alt="" /> */}

          <div class="row text-center text-md-left">
            <div class="col-md-3">
              <img src={me2} alt="" class="img-thumbnail mb-4" />
            </div>
            <div class="pl-md-4 col-md-9">
              <h6 class="title">Social Chatting Mobile App</h6>
              {/* <p class="subtitle">Web & Mobile Developer</p> */}
              <p>
              The "Social Chatting Mobile App" is a dynamic mobile application designed for the sole purpose of providing users with a fun and engaging platform for social interaction. Developed using the Flutter framework and leveraging the power of Firebase Firestore and authentication services, this app offers a seamless and secure chatting experience for users.
                <br />
                <br />


                <b>Chatting Functionality:</b>
                The app allows users to engage in real-time conversations with friends and contacts, fostering connections and social interaction.

                <br />
                <br />
                <b>Flutter and Firebase Integration:</b>
                Leveraging the Flutter framework and Firebase services, including Firestore for real-time database management and authentication for secure user access.

                <br />
                <br />
                <b>User Authentication:</b>
                Secure user authentication ensures that only authorized users can access the app, safeguarding user privacy and data integrity.

                <br />
                <br />
                <b>Data Persistence and Editing:</b>
                Attendance records are stored in Firestore with timestamps, allowing teachers to review and edit attendance data for past dates as needed. This ensures accuracy and accountability in attendance management.

                <br />
                <br />
                <b>Message Like Feature:</b>
                A unique feature allows users to express appreciation for messages by liking them. This can be done by either double-tapping a message or tapping the like button provided.

                <br />
                <br />
                The "Social Chatting Mobile App" was developed with the intention of providing users with a light-hearted and enjoyable chatting experience. While it may have started as a fun project, its seamless functionality and user-friendly interface make it suitable for a wide range of social interactions. Whether users want to connect with friends, share experiences, or simply engage in casual conversations, this app aims to facilitate meaningful interactions in a fun and intuitive way.

              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Three
