import React from 'react'

import me from '../assets/imgs/8.png'
import me2 from '../assets/imgs/8.png'

function Eight() {
  return (
    <>

      <header class="header" id="home">
        <div class="container">
          <div class="infos">
            <h6 class="title">CGPA Calculator</h6>
          </div>
          <div class="img-holder">
            <img src={me} style={{ marginBottom: '120px' }} alt="" />
          </div>
        </div>
      </header>

      <section id="about" class="section mt-3">
        <div class="container mt-5">
          {/* <img src={me} style={{ height:'540px',width:'100%' }} alt="" /> */}

          <div class="row text-center text-md-left">
            <div class="col-md-3">
              <img src={me2} alt="" class="img-thumbnail mb-4" />
            </div>
            <div class="pl-md-4 col-md-9">
              <h6 class="title">CGPA Calculator</h6>
              {/* <p class="subtitle">Web & Mobile Developer</p> */}
              <p>
                The "CGPA Calculator" is a handy mobile application specifically designed for Kerala polytechnic students to effortlessly compute their Cumulative Grade Point Average (CGPA) based on their academic results, including grades and credit scores. This app serves as a dedicated tool to aid students in managing their academic progress and achieving their educational goals.
                <br />
                <br />

                Academic Result Entry: Users can input their academic results into the app, including the grades obtained in each subject and the corresponding credit scores. The app allows for easy entry and modification of academic data, ensuring accuracy and reliability in CGPA calculation.

                <br />
                <br />
                Cumulative Grade Point Average Calculation: The app automatically calculates the CGPA based on the entered academic results. It utilizes the standard CGPA calculation formula, taking into account the grade points earned in each subject and the total credit scores to compute the overall CGPA.

                <br />
                <br />
                User-Friendly Interface: The CGPA Calculator features an intuitive and user-friendly interface, making it simple for students to input their academic data and view their CGPA instantly. The app provides a seamless experience, allowing students to stay organized and informed about their academic performance.

                <br />
                <br />
                Customizable Settings: Users can customize the app settings according to their preferences, such as adjusting the grading system or adding additional features to enhance functionality. The app offers flexibility to cater to the diverse needs of Kerala polytechnic students.

                <br />
                <br />
                The "CGPA Calculator" app is designed to empower students with the tools they need to track their academic progress effectively and make informed decisions about their education. It aims to streamline the CGPA calculation process and provide students with valuable insights to support their academic journey.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Eight