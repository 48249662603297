import React from 'react'

import me from '../assets/imgs/3.png'
import me2 from '../assets/imgs/3.png'

function Four() {
  return (
    <>

      <header class="header" id="home">
        <div class="container">
          <div class="infos">
            <h6 class="title">Data Entry: Car Service Shop</h6>

          </div>
          <div class="img-holder">
            <img src={me} style={{ marginBottom: '120px' }} alt="" />
          </div>
        </div>
      </header>

      <section id="about" class="section mt-3">
        <div class="container mt-5">
          {/* <img src={me} style={{ height:'540px',width:'100%' }} alt="" /> */}

          <div class="row text-center text-md-left">
            <div class="col-md-3">
              <img src={me2} alt="" class="img-thumbnail mb-4" />
            </div>
            <div class="pl-md-4 col-md-9">
              <h6 class="title">Data Entry: Car Service Shop</h6>
              {/* <p class="subtitle">Web & Mobile Developer</p> */}
              <p>
              The "Data Entry: Car Service Shop" project is a tailored solution developed exclusively for a car service shop located in the United Arab Emirates (UAE). This innovative application simplifies and optimizes the data entry process, enhancing operational efficiency and accuracy within the shop's workflow.
                <br />
                <br />


                <b>Employee Authentication:</b>
                Users can securely log in to the application using their unique employee ID, ensuring access control and data security.

                <br />
                <br />
                <b>Data Listing:</b>
                The application features a comprehensive list view displaying car service records, including plate numbers and car types (SUV or sedan), allowing users to easily navigate and manage existing data.

                <br />
                <br />
                <b>Efficient Data Entry:</b>
                A user-friendly interface facilitates seamless data entry, with a dedicated section for inputting new car service records. Users can enter plate numbers and select the car type (SUV or sedan) using radio buttons before adding the data.

                <br />
                <br />
                <b>Local Storage with SQL:</b>
                Data entered by users is stored locally on the mobile device using SQL database technology, ensuring data persistence and accessibility even in offline scenarios.

                <br />
                <br />
                <b>Data Synchronization with Google Sheets:</b>
                Upon user logout, the application synchronizes the locally stored data with Google Sheets using the Google Sheets API. This automated process ensures that the latest car service records are seamlessly transferred to a centralized spreadsheet for further analysis and management.

                <br />
                <br />
                The "Data Entry: Car Service Shop" project serves as a vital tool for streamlining data management processes within the car service shop. With its intuitive interface, robust data storage capabilities, and seamless integration with Google Sheets, this application empowers employees to efficiently record and manage car service data, ultimately contributing to improved service delivery and customer satisfaction.

              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Four
