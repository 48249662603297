import React from 'react'

import me from '../assets/imgs/1.png'
import me2 from '../assets/imgs/1.png'

function One() {
  return (
    <>

      <header class="header" id="home">
        <div class="container">
          <div class="infos">
            <h6 class="title">School Web Portal</h6>
            <p>For HSS Kunnamangalam</p>
          </div>
          <div class="img-holder">
            <img src={me} style={{ marginBottom: '120px' }} alt="" />
          </div>
        </div>
      </header>

      <section id="about" class="section mt-3">
        <div class="container mt-5">
          {/* <img src={me} style={{ height:'540px',width:'100%' }} alt="" /> */}

          <div class="row text-center text-md-left">
            <div class="col-md-3">
              <img src={me2} alt="" class="img-thumbnail mb-4" />
            </div>
            <div class="pl-md-4 col-md-9">
              <h6 class="title">School Web Portal</h6>
              {/* <p class="subtitle">Web & Mobile Developer</p> */}
              <p>
                The "School Portal" project is a comprehensive web application designed exclusively for HSS Kunnamangalam. Targeted specifically for teachers, this portal serves as a centralized platform to streamline various administrative tasks and enhance classroom management.
                <br />
                <br />


                <b>Student Management:</b>
                The portal encompasses a complete student database, organized by classes, providing teachers with easy access to student information.

                <br />
                <br />
                <b>Attendance Tracking:</b>
                Teachers can efficiently mark attendance for their classes on a daily basis, specifying the period number. Attendance data is seamlessly stored in Firestore, ensuring reliability and accessibility.

                <br />
                <br />
                <b>Automatic Communication:</b>
                Attendance records are automatically forwarded to the registered class teacher's WhatsApp number, facilitating real-time updates and communication.

                <br />
                <br />
                <b>Data Persistence and Editing:</b>
                Attendance records are stored in Firestore with timestamps, allowing teachers to review and edit attendance data for past dates as needed. This ensures accuracy and accountability in attendance management.

                <br />
                <br />
                <b>Cross-Teacher Collaboration:</b>
                The portal enables teachers to view the working charts of their colleagues, fostering collaboration and coordination among the faculty members.

                <br />
                <br />
                <b>Upcoming Plans Section:</b>
                A dedicated section within the portal allows teachers to outline their upcoming plans and schedules, promoting effective lesson planning and curriculum development.

                <br />
                <br />
                <b>Customizable Working Charts:</b>
                Teachers have the flexibility to create and customize working charts tailored to their specific schedules and working days, enhancing workflow efficiency.

                <br />
                <br />
                <b>Launch Date:</b>
                The "School Portal" project is scheduled to launch at HSS Kunnamangalam at the beginning of the academic year 2024, revolutionizing classroom management and administrative processes for the faculty.

              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default One
