import React from 'react'

import me from '../assets/imgs/6.png'
import me2 from '../assets/imgs/6.png'

function Six() {
  return (
    <>

      <header class="header" id="home">
        <div class="container">
          <div class="infos">
            <h6 class="title">Personal Chatting Mobile App</h6>
          </div>
          <div class="img-holder">
            <img src={me} style={{ marginBottom: '120px' }} alt="" />
          </div>
        </div>
      </header>

      <section id="about" class="section mt-3">
        <div class="container mt-5">
          {/* <img src={me} style={{ height:'540px',width:'100%' }} alt="" /> */}

          <div class="row text-center text-md-left">
            <div class="col-md-3">
              <img src={me2} alt="" class="img-thumbnail mb-4" />
            </div>
            <div class="pl-md-4 col-md-9">
              <h6 class="title">Personal Chatting Mobile App</h6>
              {/* <p class="subtitle">Web & Mobile Developer</p> */}
              <p>
              The "Personal Chatting Mobile App" is a fun and engaging application designed for casual communication and interaction between users. Developed as a personal project, this mobile app offers a user-friendly platform for users to connect, chat, and share content with friends and contacts.
                <br />
                <br />


                <b>User Authentication:</b>
                Users can securely log in to the app using their mobile number and personal details, ensuring access control and user privacy.

                <br />
                <br />
                <b>User Discovery and Search:</b>
                The Home Screen presents users with a list of registered users, allowing for easy discovery and connection. Additionally, users can search for specific users by name or other criteria.

                <br />
                <br />
                <b>Messaging Features:</b>
                The messaging interface enables users to send text messages, images, and emojis to their contacts. Messages display delivery and seen timestamps, providing users with real-time communication feedback.
                User status indicators at the top of the chat screen indicate whether a contact is online or offline, enhancing the chat experience.

                <br />
                <br />
                <b>Media Gallery Integration:</b>
                Users have the option to save sent images to their device's gallery, allowing them to preserve and revisit shared content outside of the app.

                <br />
                <br />
                <b>Message Deletion:</b>
                Users can delete sent messages, providing control over their chat history and ensuring privacy and discretion in communication.

                <br />
                <br />
                The "Personal Chatting Mobile App" was developed as a personal project aimed at providing users with a fun and convenient platform for casual communication and interaction. With its intuitive interface, versatile messaging features, and emphasis on user privacy, this app offers a seamless and enjoyable chatting experience for users seeking to connect with friends and contacts.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Six
