import './assets/css/steller.css'

// images
import logo from './assets/imgs/logo.png'
import me from './assets/imgs/me.png'
import me2 from './assets/imgs/me2.png'
import p1 from './assets/imgs/1.png'
import p2 from './assets/imgs/2.png'
import p3 from './assets/imgs/3.png'
import p4 from './assets/imgs/4.png'
import p5 from './assets/imgs/5.png'
import p6 from './assets/imgs/6.png'
import p7 from './assets/imgs/7.png'
import p8 from './assets/imgs/8.png'


// firebase
import { collection, addDoc } from 'firebase/firestore';
import { db, auth } from './firebase/config';
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

function App() {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const navigate = useNavigate();

  const [btn, setBtn] = useState('Send Message');

  const AddDataToFirestore = async (e) => {
    e.preventDefault();
    if (name.length > 0 && email.length > 0 && message.length > 0) {
      try {
        setBtn('Sending..')
        const data = {
          name: name,
          email: email,
          message: message,
        };

        const docRef = await addDoc(collection(db, 'messages'), data).then(() => {
          alert('Message sent ✔️');
          setEmail('');
          setName('');
          setMessage('');
        })
        setBtn('Send Message')
        console.log('Document added with ID: ', docRef.id);
      } catch (error) {
        setBtn('Send Message')
        console.error('Error adding document: ', error);
      }
    } else {
      alert("Please Enter Your Details")
    }

  }

  return (
    <>
      <nav class="navbar navbar-expand-lg navbar-light fixed-top" data-spy="affix" data-offset-top="0">
        <div class="container">
          <a class="navbar-brand" href="#"><img src={logo} style={{ height: '50px', width: '50px' }}
            alt="" /></a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto align-items-center">
              <li class="nav-item">
                <a class="nav-link" href="#home">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#about">About</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#service">Service</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#portfolio">Portfolio</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#contact">Contact</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>



      <header class="header" id="home">
        <div class="container">
          <div class="infos">
            <h6 class="subtitle">hello,I'm</h6>
            <h6 class="title">Mirza Aman</h6>
            <p>Web & Mobile Developer</p>

            <div class="socials mt-4">
              <a class="social-item" href="https://www.instagram.com/mrzzzman/"><i class="icon fa-brands fa-instagram"></i></a>
            </div>
          </div>
          <div class="img-holder">
            <img src={me} style={{ marginBottom: '120px' }} alt="" />
          </div>
        </div>
      </header>

      <section id="about" class="section mt-3">
        <div class="container mt-5">
          <div class="row text-center text-md-left">
            <div class="col-md-3">
              <img src={me2} alt="" class="img-thumbnail mb-4" />
            </div>
            <div class="pl-md-4 col-md-9">
              <h6 class="title">Mirza Aman</h6>
              <p class="subtitle">Web & Mobile Developer</p>
              <p>I approach development with a problem-solving mindset. When presented with a new project, I may
                initially struggle to define the development structure. However, my innate curiosity and
                research-oriented approach drive me to find innovative solutions. To date, I've successfully
                tackled all work-related challenges, ensuring every project is completed to the highest
                standards.</p>
            </div>
          </div>
        </div>
      </section>

      <section id="service" class="section">
        <div class="container text-center">
          <h6 class="subtitle">Service</h6>
          <h6 class="section-title mb-4">What I Do</h6>
          <p class="mb-5 pb-4">A Glimpse into What I Do</p>

          <div class="row">
            <div class="col-sm-6 col-md-3 mb-4">
              <div class="custom-card card border">
                <div class="card-body">
                  <i class="icon material-symbols-outlined">
                    error
                  </i>
                  <h5>Problem Solving</h5>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-3 mb-4">
              <div class="custom-card card border">
                <div class="card-body">
                  <i class="icon fa-solid fa-desktop"></i>
                  <h5>Web Development</h5>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-3 mb-4">
              <div class="custom-card card border">
                <div class="card-body">
                  <i class="icon fa-solid fa-mobile"></i>
                  <h5>App Development</h5>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-3 mb-4">
              <div class="custom-card card border">
                <div class="card-body">
                  <i class="icon fa-solid fa-pen-nib"></i>
                  <h5>UI Designing</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="portfolio" class="section">
        <div class="container text-center">
          <h6 class="subtitle">Portfolio</h6>
          <h6 class="section-title mb-4">Check My Wonderful Works</h6>
          <p class="mb-5 pb-4">Discover the Depth of My Talents: Take a Look at My Wonderful Works</p>

          <div class="row">
            <div class="col-sm-4">
              <div class="img-wrapper">
                <img src={p1} alt="" />
                <div class="overlay">
                  <div class="overlay-infos">
                    <h5>School Portal</h5>
                    <a onClick={()=>navigate('/project:portal')} style={{cursor:'pointer'}} ><i class="ti-zoom-in"></i></a>
                  </div>
                </div>
              </div>
              <div class="img-wrapper">
                <img src={p2} alt="" />
                <div class="overlay">
                  <div class="overlay-infos">
                    <h5>Online Paintings Store</h5>
                    <a onClick={()=>navigate('/project:paintings')} style={{cursor:'pointer'}} ><i class="ti-zoom-in"></i></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="img-wrapper">
                <img src={p4} alt="" />
                <div class="overlay">
                  <div class="overlay-infos">
                    <h5>Social Chatting Mobile App</h5>
                    <a onClick={()=>navigate('/project:socialchat')} style={{cursor:'pointer'}} ><i class="ti-zoom-in"></i></a>
                  </div>
                </div>
              </div>
              <div class="img-wrapper">
                <img src={p3} alt="" />
                <div class="overlay">
                  <div class="overlay-infos">
                    <h5>Service Data Entry App</h5>
                    <a onClick={()=>navigate('/project:service')} style={{cursor:'pointer'}} ><i class="ti-zoom-in"></i></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="img-wrapper">
                <img src={p5} alt="" />
                <div class="overlay">
                  <div class="overlay-infos">
                    <h5>Recruitment Managing Mobile App</h5>
                    <a onClick={()=>navigate('/project:rcm')} style={{cursor:'pointer'}} ><i class="ti-zoom-in"></i></a>
                  </div>
                </div>
              </div>
              <div class="img-wrapper">
                <img src={p6} alt="" />
                <div class="overlay">
                  <div class="overlay-infos">
                    <h5>Personal Chatting Mobile App</h5>
                    <a onClick={()=>navigate('/project:personalchat')} style={{cursor:'pointer'}} ><i class="ti-zoom-in"></i></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="img-wrapper">
                <img src={p7} alt="" />
                <div class="overlay">
                  <div class="overlay-infos">
                    <h5>Personal Hobbies Tracking Mobile App</h5>
                    <a onClick={()=>navigate('/project:hobbies')} style={{cursor:'pointer'}} ><i class="ti-zoom-in"></i></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="img-wrapper">
                <img src={p8} alt="" />
                <div class="overlay">
                  <div class="overlay-infos">
                    <h5>CGPA Finder</h5>
                    <a onClick={()=>navigate('/project:cgpaFinder')} style={{cursor:'pointer'}} ><i class="ti-zoom-in"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section class="bg-gray p-0 section">
        <div class="container">
            <div class="card bg-primary">
                <div class="card-body text-light">
                    <div class="row align-items-center">
                        <div class="col-sm-9 text-center text-sm-left">
                            <h5 class="mt-3">Hire Me For Your Project</h5>
                            <p class="mb-3">Accusantium labore nostrum similique quisquam.</p>
                        </div>
                        <div class="col-sm-3 text-center text-sm-right">
                            <button class="btn btn-light rounded">Hire Me!</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
      <section id="contact" class="position-relative section">
        <div class="container text-center">
          <h6 class="subtitle">Contact</h6>
          <h6 class="section-title mb-4">Get In Touch With Me</h6>
          <p class="mb-5 pb-4">Let's Connect and Make Magic Happen!</p>

          <div class="contact text-left">
            <div class="form">
              <h6 class="subtitle">Available 24/7</h6>
              <h6 class="section-title mb-4">Get In Touch</h6>
              <form>
                <div class="form-group">
                  <input type="text" class="form-control" id="exampleInputEmail1"
                    aria-describedby="emailHelp" placeholder="Name" required value={name} onChange={(e) => setName(e.target.value)} />
                </div>
                <div class="form-group">
                  <input type="email" class="form-control" id="exampleInputPassword1"
                    placeholder="Email" required value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div class="form-group">
                  <textarea name="contact-message" id="" cols="30" rows="5" class="form-control"
                    placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)} ></textarea>
                </div>
                <button onClick={AddDataToFirestore} class="btn btn-primary btn-block rounded w-lg">{btn}</button>
              </form>
            </div>
            <div class="contact-infos">
              <div class="item">
                <i class="fa-solid fa-location-dot"></i>
                <div class="">
                  <h5>Location</h5>
                  <p>Kunnamangalam, Calicut </p>
                </div>
              </div>
              <div class="item">
                <i class="fa-solid fa-phone"></i>
                <div>
                  <h5>Phone Number</h5>
                  <p>(+91) 8129004343</p>
                </div>
              </div>
              <div class="item">
                <i class="fa-solid fa-envelope"></i>
                <div class="mb-0">
                  <h5>Email Address</h5>
                  <p>mzawyd@gmail.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer class="page-footer">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-sm-6">
              <p>Copyright
                <script>document.write(new Date().getFullYear())</script> &copy; <a
                  href="http://www.mitcode.in" target="_blank">MIRZADev</a>
              </p>
            </div>
            <div class="col-sm-6">
              <div class="socials">
                <a class="social-item" href="https://www.instagram.com/mrzzzman"><i class="icon fa-brands fa-instagram"></i></a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default App;
