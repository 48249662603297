import React from 'react'

import me from '../assets/imgs/2.png'
import me2 from '../assets/imgs/2.png'

function Two() {

  const name  = "HBS Paintings";
  return (
    <>

      <header class="header" id="home">
        <div class="container">
          <div class="infos">
            <h6 class="title">{name}</h6>
            <p>Paintings Online Store</p>
          </div>
          <div class="img-holder">
            <img src={me} style={{ marginBottom: '120px' }} alt="" />
          </div>
        </div>
      </header>

      <section id="about" class="section mt-3">
        <div class="container mt-5">
          {/* <img src={me} style={{ height:'540px',width:'100%' }} alt="" /> */}

          <div class="row text-center text-md-left">
            <div class="col-md-3">
              <img src={me2} alt="" class="img-thumbnail mb-4" />
            </div>
            <div class="pl-md-4 col-md-9">
              <h6 class="title">{name}</h6>
              {/* <p class="subtitle">Web & Mobile Developer</p> */}
              <p>
              "HBS Paintings" is an innovative online platform developed exclusively for the esteemed artist Beevi Fathima. This project aims to establish an online presence for Beevi Fathima's exquisite collection of paintings, providing art enthusiasts with a convenient avenue to explore and purchase her creations.
                <br />
                <br />


                <b>Online Art Gallery::</b>
                The platform showcases a diverse range of paintings meticulously crafted by Beevi Fathima, allowing visitors to browse through the collection and admire the artistic brilliance.

                <br />
                <br />
                <b>E-commerce Functionality:</b>
                Built-in e-commerce functionality enables seamless transactions, allowing customers to purchase their favorite paintings directly from the website.

                <br />
                <br />
                <b>Secure Payment Gateway Integration:</b>
                PhonePe, a trusted payment gateway, is seamlessly integrated into the platform, ensuring secure and hassle-free transactions for customers.

                <br />
                <br />
                <b>Automated Email Service:</b>
                EmailJS integration facilitates automated email services for both customers and the seller (Beevi Fathima). This includes order confirmation emails for customers and notifications for Beevi Fathima upon receiving new orders, enhancing communication and efficiency.

                <br />
                <br />
                <b>Launch Date:</b>
                "HBS Paintings: Paintings Online Store" is scheduled to launch in July 2024, marking a significant milestone in Beevi Fathima's journey as an artist. This platform not only serves as a marketplace for her creations but also represents a digital showcase of her artistic talent and vision.

              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Two
