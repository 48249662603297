import React from 'react'

import me from '../assets/imgs/5.png'
import me2 from '../assets/imgs/5.png'

function Five() {
  return (
    <>

      <header class="header" id="home">
        <div class="container">
          <div class="infos">
            <h6 class="title">Recruitment Manager App</h6>
            <p>enaam Travel & Tours</p>
          </div>
          <div class="img-holder">
            <img src={me} style={{ marginBottom: '120px' }} alt="" />
          </div>
        </div>
      </header>

      <section id="about" class="section mt-3">
        <div class="container mt-5">
          {/* <img src={me} style={{ height:'540px',width:'100%' }} alt="" /> */}

          <div class="row text-center text-md-left">
            <div class="col-md-3">
              <img src={me2} alt="" class="img-thumbnail mb-4" />
            </div>
            <div class="pl-md-4 col-md-9">
              <h6 class="title">Recruitment Management Mobile App: for Enaam Travel & Tours</h6>
              {/* <p class="subtitle">Web & Mobile Developer</p> */}
              <p>
              The "Recruitment Management Mobile App" is a specialized application developed exclusively for Enaam Travel & Tours to streamline and enhance their recruitment processes. This mobile app serves as a comprehensive tool for managing job entries, candidate profiles, and final selections, providing administrators with efficient control and oversight over the recruitment workflow.
                <br />
                <br />


                <b>Admin Authentication:</b>
                The application features secure authentication using Firebase Authentication, allowing authorized administrators to log in using their unique admin ID and password.

                <br />
                <br />
                <b>Job Entry Management:</b>
                Upon logging in, administrators are presented with a home page displaying a list of job entries, including relevant data and file numbers. An "Add" icon enables administrators to create new job entries, facilitating seamless entry management.

                <br />
                <br />
                <b>Automatic Communication:</b>
                Attendance records are automatically forwarded to the registered class teacher's WhatsApp number, facilitating real-time updates and communication.

                <br />
                <br />
                <b>Candidate Management:</b>
                Within each job entry, administrators can navigate to a dedicated page for managing candidates. Here, administrators can add new candidates, assign file numbers automatically generated by the system, and mark candidates as final selections.

                <br />
                <br />
                <b>Final Candidate Selection:</b>
                Upon marking a candidate as the final selection, the "Candidates" button transforms into a "Final Candidate" button, providing quick access to the details of the chosen candidate. This feature streamlines the final selection process and ensures clarity and transparency in candidate management.

                <br />
                <br />
                The "Recruitment Management Mobile App" serves as a pivotal tool for Enaam Travel & Tours in optimizing their recruitment processes. With its user-friendly interface, secure authentication, and seamless candidate management features, this application empowers administrators to efficiently handle job entries, evaluate candidates, and make informed final selections.

              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Five
